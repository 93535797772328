<!--
 * @Author: LXG
 * @Date: 2021-04-26
 * @LastEditTime: 2021-04-26
 * @LastEditor: LXG
 * @Description: 表单组件 - 文本域
-->
<template>
    <el-input
        v-model="cpu_value"
        type="textarea"
        :size="size"
        :placeholder="placeholder"
        :disabled="disabled"
        :maxlength="maxlength"
        :show-word-limit="showWordLimit"
        :rows="rows"
        :autosize="autosize"
    ></el-input>
</template>

<script>
export default {
    name: 'FormTextarea',
    props: {
        value: {},
        size: {
            type: String,
            default: 'small'
        },
        placeholder: {
            type: String,
            default: '请输入'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        maxlength: {
            type: Number,
            default: 100
        },
        showWordLimit: {
            type: Boolean,
            default: true
        },
        rows: {
            type: Number,
            default: 4
        },
        autosize: {
            type: [Boolean, Object],
            default: () => { return { minRows: 4 } }
        },
    },
    computed: {
        cpu_value: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
    },
    methods: {
    },
}
</script>