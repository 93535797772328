<!--
 * @Author: LXG
 * @Date: 2021-04-20
 * @LastEditTime: 2021-04-28
 * @LastEditor: LXG
 * @Description: 表单组件 - 日期选择
-->
<template>
    <el-date-picker
        v-model="cpu_value"
        :size="size"
        :placeholder="placeholder"
        :clearable="clearable"
        :value-format="valueFormat"
        @change="change"
    ></el-date-picker>
</template>

<script>
export default {
    name: 'FormDatePicker',
    props: {
        value: {},
        size: {
            type: String,
            default: 'medium'
        },
        placeholder: {
            type: String,
            default: '请选择'
        },
        clearable: {
            type: Boolean,
            default: true,
        },
        valueFormat: {
            type: [String],
            default: 'yyyy-MM-dd'
        },
        startDate: {
            type: [String],
        },
        endDate: {
            type: [String],
        },
    },
    computed: {
        cpu_value: {
            get() {
                return this.value
            },
            set(val) {
                if (this.endDate) {
                    if (this.endDate < val) {
                        let temp = this.endDate
                        this.$emit('update:endDate', val)
                        this.$emit('input', temp)
                    } else {
                        this.$emit('input', val)
                    }
                } else if (this.startDate) {
                    if (val < this.startDate) {
                        let temp = this.startDate
                        this.$emit('update:startDate', val)
                        this.$emit('input', temp)
                    } else {
                        this.$emit('input', val)
                    }
                } else {
                    this.$emit('input', val)
                }
            }
        },
    },
    methods: {
        change(val) {
            // console.log('change:', val)

            this.$nextTick(() => {
                this.$emit('change', val)
            })
        },
    },
}
</script>