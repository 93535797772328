<!--
 * @Author: LXG
 * @Date: 2021-04-21
 * @LastEditor: LXG
 * @LastEditTime: 2021-05-18
 * @Description: 表单组件 - 文件上传
-->
<template>
    <div class="form-file-uploader">
        <!-- 上传区域 -->
        <div
            v-if="uploadArea&&!disabled"
            class="ffu-upload"
            title="点击上传"
            :size="areaSize"
            @click="clickUpload"
        >
            <div class="ffu-upload-inner">
                <!-- <p>点击或拖拽</p> -->
                <i class="el-icon-plus"></i>
            </div>
        </div>
        <!-- 列表区域 -->
        <div class="ffu-files">
            <div
                v-for="(item,index) in cpu_value"
                :key="index"
                class="file-item"
            >
                <!-- uploading -->
                <div
                    v-if="item.uploading"
                    class="fileItem-uploading"
                >
                    <span>上传中</span>
                </div>
                <!-- icon/缩略图 -->
                <div class="fileItem-thumbnail">
                    <img
                        v-if="cpu_isImg(item)"
                        :src="item.url"
                        @click="previewImg(item)"
                    />
                    <TjIcon
                        v-else
                        group="iconf"
                        :name="cpu_fileIcon(item)"
                    ></TjIcon>
                </div>
                <!-- 文件信息 -->
                <div class="fileItem-content">
                    <p :title="item[nameKey]">{{item[nameKey]}}</p>
                </div>
                <!-- 操作 -->
                <div class="fileItem-operate">
                    <i
                        v-if="item.url"
                        class="el-icon-download icon icon-download"
                        @click="downloadFile(item,index)"
                    ></i>
                    <i
                        v-if="!disabled"
                        class="el-icon-delete icon icon-delete"
                        @click="deleteFile(item,index)"
                    ></i>
                </div>
            </div>
        </div>
        <!-- 隐藏的input -->
        <input
            v-show="false"
            ref="fileInput"
            type="file"
            multiple
            @change="changeInput"
        />
        <!-- 图片预览 -->
        <el-image-viewer
            v-show="imgViewerInfo.show"
            :url-list="imgViewerInfo.urlList"
            :on-close="closePreview"
        ></el-image-viewer>
    </div>
</template>

<script>
import TjIcon from '@/components/tjIcon/TjIcon'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

class Filer {
    constructor(File) {
        this.file = File // File

        this.name = File.name // 文件名
        this.size = (File.size / 1024) // 文件大小(KB)
        this.type = File.type // 文件类型
        this.suffix = File.name.substring(
            File.name.lastIndexOf('.') + 1,
            File.name.length
        ) // 文件后缀名

        this.url = undefined // URL
        if (File.type && File.type.includes('image')) {
            let fr = new FileReader()
            fr.readAsDataURL(File)
            fr.onload = e => {
                this.url = e.target.result
            }
        }
        this.uploading = false // 上传状态位

        // console.log('new Filer:', this)
    }
}

// 图片文件格式
const IMG_SUFFIX = ['jpg', 'jpeg', 'png', 'gif', 'bmp']

export default {
    name: 'FormFileUploader',
    components: {
        TjIcon,
        ElImageViewer,
    },
    props: {
        value: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        // 是否显示上传区域
        uploadArea: {
            type: Boolean,
            default: true
        },
        // 上传区域大小
        areaSize: {
            type: String,
            default: 'normal'
        },
        // 限制最多文件数
        maxNum: {
            type: [Number, String],
            default: 5
        },
        // 限制最大文件大小(MB)
        maxSize: {
            type: [Number, String],
            default: 10
        },
        // 限制文件格式
        suffix: {
            type: Array,
            default: () => []
        },
        nameKey: {
            type: String,
            default: 'name'
        },
        // 是否选择文件后自动上传
        upload: {
            type: Boolean,
            default: false
        },
        // 选择文件后的钩子
        afterSelect: {
            type: Function,
            default: function () { }
        },
    },
    data() {
        return {
            // 图片预览
            imgViewerInfo: {
                show: false,
                urlList: []
            },
        }
    },
    computed: {
        cpu_value: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
        /**
         * @description: 是否达到最多文件数
         */
        cpu_isMaxNum() {
            if (!this.maxNum) return false

            if (parseInt(this.maxNum) <= this.cpu_value.length) return true

            return false
        },
        /**
         * @description: 是否达到最多文件数
         * @param {Number} fileSize 文件大小
         */
        cpu_isMaxSize() {
            return function (fileSize) {
                if (!this.maxSize) return false

                if (Number(this.maxSize) < fileSize) return true

                return false
            }
        },
        /**
         * @description: 是否为不支持的文件格式
         * @param {String} fileSuffix 文件格式
         */
        cpu_isNotSuffix() {
            return function (fileSuffix) {
                if (!this.suffix.length) return false

                if (this.suffix.includes(fileSuffix)) return false

                return true
            }
        },
        cpu_isImg() {
            return function (filer) {
                // 优先判断文件类型，再判断文件格式
                if (filer.type && filer.type.includes('image')) {
                    return true
                }
                if (!filer.suffix) {
                    filer.suffix = filer.name.substring(
                        filer.name.lastIndexOf('.') + 1,
                        filer.name.length
                    )
                }
                return IMG_SUFFIX.includes(filer.suffix.toLowerCase())
            }
        },
        cpu_fileIcon() {
            return function (filer) {
                switch (filer.suffix.toLowerCase()) {
                    case 'doc':
                    case 'docx':
                        return 'doc'
                    case 'xls':
                    case 'xlsx':
                        return 'xls'
                    case 'ppt':
                    case 'pptx':
                        return 'ppt'
                    case 'txt':
                        return 'txt'
                    case 'zip':
                        return 'zip'
                    default:
                        return 'unknow'
                }
            }
        },
    },
    methods: {
        /**
         * @description: 点击选择
         */
        clickUpload() {
            if (this.cpu_isMaxNum) {
                this.$alert('已达到最多文件数')
                return
            }
            this.$refs['fileInput'].click()
        },
        /**
         * @description: 选择文件
         * @param {Event} e Event
         */
        changeInput(e) {
            // console.log('changeInput:', e)
            const files = e.target.files

            // 声明几个数组存放选择失败的文件，用于弹窗提示
            let [errorMaxNum, errorMaxSize, errorSuffix] = [[], [], []]
            for (let i = 0; i < files.length; i++) {
                const f = files[i]
                // 校验 文件数
                if (this.cpu_isMaxNum) {
                    errorMaxNum.push('【' + f.name + '】')
                    continue
                }
                // 校验 文件大小
                if (this.cpu_isMaxSize(f.size / (1024 * 1024))) {
                    errorMaxSize.push('【' + f.name + '】')
                    continue
                }
                // 校验 文件格式
                let fSuffix = f.name.substring(
                    f.name.lastIndexOf('.') + 1, f.name.length
                ).toLowerCase()
                if (this.cpu_isNotSuffix(fSuffix)) {
                    errorSuffix.push('【' + f.name + '】')
                    continue
                }
                let filer = new Filer(f)
                if (this.upload) {
                    filer.uploading = true
                }
                this.cpu_value.push(filer)
                let currentIndex = this.cpu_value.length - 1
                this.afterSelect(filer, (uploadStatus) => {
                    if (uploadStatus) {
                        this.$set(filer, 'uploading', !uploadStatus)
                        this.$forceUpdate()
                    } else {
                        this.cpu_value.splice(currentIndex, 1)
                    }
                })
            }
            this.alertChangeError(errorMaxNum, errorMaxSize, errorSuffix)

            // 操作完将value赋空，避免再次选择相同文件时不会触发change事件
            e.target.value = ''
        },
        /**
         * @description: 弹窗提示选择失败的文件
         * @param {Array} errorMaxNum 超出最多文件数
         * @param {Array} errorMaxSize 超出最大文件大小
         * @param {Array} errorSuffix 不支持的文件格式
         */
        alertChangeError(errorMaxNum, errorMaxSize, errorSuffix) {
            let list = []
            const h = this.$createElement
            if (!!errorMaxNum.length) {
                list.push(h('p', null, `超出最多文件数：${errorMaxNum.join('')}；`))
            }
            if (!!errorMaxSize.length) {
                list.push(h('p', null, `超出最大文件大小：${errorMaxSize.join('')}；`))
            }
            if (!!errorSuffix.length) {
                // list.push(h('p', null, `不支持的文件格式：${errorSuffix.join('')}；`))
                list.push(h('p', null, `文件格式限制为PDF`))
            }
            if (!!list.length) {
                this.$alert(
                    h('div', null, list),
                    '选择文件失败'
                )
            }
        },
        /**
         * @description 下载文件
         * @param {Filer||Object} filer Filer
         * @param {Number} index 下标
         */
        downloadFile(filer, index) {
            let download = document.createElement('a')
            download.setAttribute('download', '')
            download.href = `${this.$httpApi.baseUrl}/dev-api/fileManageApi/common/fileView/${filer.fileid}`
            download.click()
        },
        /**
         * @description: 删除文件
         * @param {Filer||Object} filer 文件信息
         * @param {Number} index 下标
         */
        deleteFile(filer, index) {
            this.$confirm('确认删除所选文件', '删除确认', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(() => {
                let beforeList = [...this.cpu_value]
                this.cpu_value.splice(index, 1)
                this.$emit('delete', {
                    target: filer,
                    oldList: beforeList,
                    newList: this.cpu_value,
                })
            }).catch(() => { })
        },
        /**
         * @description: 预览图片
         * @param {Filer||Object} filer 文件信息
         */
        previewImg(filer) {
            this.imgViewerInfo.urlList = [filer.url]
            this.imgViewerInfo.show = true
        },
        /**
         * @description: 关闭图片预览
         */
        closePreview() {
            this.imgViewerInfo.show = false
        },
    },
    filters: {
    },
}
</script>

<style lang="scss" scoped>
@import "@/styles/com/theme.scss";

.form-file-uploader {
    overflow: hidden;
}

.ffu-upload {
    float: left;
    display: -moz-grid;
    display: -ms-grid;
    display: grid;
    justify-content: center;
    align-content: center;
    width: 110px;
    height: 110px;
    border: 1px dashed #cccccc;
    border-radius: 4px;
    margin: 10px 20px 10px 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
    transition: all 0.2s linear 0s;

    .ffu-upload-inner {
        text-align: center;
    }
    &[size="small"] {
        width: 50px;
        height: 50px;
    }
    &:hover {
        border-color: $color-primary;
    }
}
.ffu-files {
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow: hidden;
}
.file-item {
    position: relative;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 5px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    .fileItem-uploading {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        text-align: center;
        color: #ffffff;
        background-color: rgba(#333333, 0.8);
        z-index: 9;
    }
    .fileItem-thumbnail {
        width: 38px;
        height: 100%;

        img,
        .tj-icon {
            width: 100%;
            height: 100%;
        }
        img {
            cursor: pointer;
        }
    }
    .fileItem-content {
        width: 200px;
        padding-right: 10px;
        padding-left: 10px;
        font-weight: bold;

        p {
            white-space: nowrap;
            -ms-text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
    .fileItem-operate {
        .icon {
            font-size: 16px;
            color: #cccccc;
            cursor: pointer;
            -webkit-transition: all 0.2s linear 0s;
            -moz-transition: all 0.2s linear 0s;
            -o-transition: all 0.2s linear 0s;
            transition: all 0.2s linear 0s;

            &.icon-download:hover {
                color: $color-primary;
            }
            &.icon-delete:hover {
                color: $color-danger;
            }
        }
        .icon + .icon {
            margin-left: 10px;
        }
    }

    &:hover {
        box-shadow: 0 0 5px 0 #cccccc;
    }
}

.el-image-viewer__wrapper {
    z-index: 9999 !important;

    .el-image-viewer__close {
        color: #ffffff;
    }
}
</style>