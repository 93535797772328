<!--
 * @Author: LXG
 * @Date: 2021-04-20
 * @LastEditTime: 2021-06-02
 * @LastEditors: Please set LastEditors
 * @Description: 表单组件 - 下拉选择
-->
<template>
    <el-select
        class="form-select"
        v-model="cpu_value"
        :size="size"
        :disabled="disabled"
        :clearable="clearable"
        @change="change"
    >
        <el-option
            v-for="(item,index) in options"
            :key="index"
            :value="item[valueKey]"
            :label="item[labelKey]"
        ></el-option>
    </el-select>
</template>

<script>
export default {
    name: 'FormSelect',
    props: {
        value: {},
        size: {
            type: String,
            default: 'medium'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: true
        },
        options: {
            type: Array,
            default: () => []
        },
        valueKey: {
            type: String,
            default: 'value'
        },
        labelKey: {
            type: String,
            default: 'label'
        },
    },
    computed: {
        cpu_value: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
    },
    methods: {
        change(val) {
            let index = this.options.findIndex(cv => cv[this.valueKey] === val)

            this.$emit('change', val, this.options[index], index)
        },
    },
}
</script>