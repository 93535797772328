<!--
 * @Author: LXG
 * @Date: 2021-05-08
 * @Editors: LXG
 * @LastEditTime: 2021-05-20
 * @Description: 表单组件 - 选择联想
-->
<template>
    <el-select
        class="form-select-auto-complete"
        v-model="cpu_value"
        :size="size"
        :placeholder="placeholder"
        :clearable="clearable"
        filterable
        :remote="lazy"
        :remote-method="lazySearch"
        :loading="loading"
    >
        <slot>
            <el-option
                v-for="(item,index) in cpu_options"
                :key="index"
            ></el-option>
        </slot>
    </el-select>
</template>

<script>
// 防抖函数
let debounce = (function () {
    let timer = null
    return function (fn, delay = 200) {
        let context = this
        let args = arguments

        timer && clearTimeout(timer)
        timer = setTimeout(() => {
            fn.applay(context, args)
        }, delay)
    }
})()

export default {
    name: 'FormSelectAutoComplete',
    props: {
        value: {},
        size: {
            type: String,
            default: 'medium',
        },
        placeholder: {
            type: String,
            default: '请输入并选择'
        },
        clearable: {
            type: Boolean,
            default: true
        },
        // 懒联想(远程联想)
        lazy: {
            type: Boolean,
            default: true
        },
        // 自定义懒联想方法
        lazyMethod: {
            type: Function,
        },
        options: {
            type: Array,
            default: () => []
        },
        valueKey: {
            type: String,
            default: 'value'
        },
        labelKey: {
            type: String,
            default: 'label'
        },
    },
    data() {
        return {
            temp_value: '',
            lazyOptions: [], // 懒联想选项列表
            loading: false, // 懒联想加载中
        }
    },
    computed: {
        cpu_value: {
            get() {
                return this.value || this.temp_value
            },
            set(val) {
                this.temp_value = val
                this.$emit('input', val)
            }
        },
        cpu_options() {
            if (this.lazy) return this.lazyOptions || []

            return this.options || []
        },
    },
    methods: {
        /**
         * @description: 懒搜索
         * @param {String} keyword 搜索关键字
         */
        lazySearch(keyword) {
            // console.log('lazySearch:', keyword)
            this.loading = true
            this.lazyOptions = []
            if (this.lazyMethod) {
                this.lazyMethod(keyword, (options) => {
                    // console.log('lazyMethod:', options)
                    this.lazyOptions = options
                    this.loading = false
                })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
</style>