<!--
 * @Author: LXG
 * @Date: 2021-05-21
 * @Editors: LXG
 * @LastEditTime: 2021-05-21
 * @Description: 表单组件 - 单选组
-->
<template>
    <el-radio-group
        class="form-checkbox-group"
        v-model="cpu_value"
        :size="size"
        :disabled="disabled"
        :fill="fill"
        @change="change"
    >
        <el-checkbox
            v-for="(item,index) in options"
            :key="index"
            :label="item[valueKey]"
        >
            {{item[labelKey]}}
        </el-checkbox>
    </el-radio-group>
</template>

<script>
import variableStyle from '@/styles/variable.scss'

export default {
    name: 'FormCheckboxGroup',
    props: {
        value: {},
        size: {
            type: String,
            default: 'medium'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        // 激活状态背景色(边框色)
        fill: {
            type: String,
            default: variableStyle['color-theme']
        },
        // 选项列表
        options: {
            type: Array,
            default: () => []
        },
        valueKey: {
            type: String,
            default: 'value'
        },
        labelKey: {
            type: String,
            default: 'label'
        },
    },
    computed: {
        cpu_value: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
    },
    methods: {
        change(val) {
            this.$emit('change', val)
        },
    },
}
</script>