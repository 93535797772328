<!--
 * @Author: LXG
 * @Date: 2021-04-20
 * @LastEditTime: 2021-06-15
 * @LastEditor: LXG
 * @Description: 表单组件 - 输入框
-->
<template>
    <el-input
        class="form-input"
        v-model="cpu_value"
        :size="size"
        :placeholder="placeholder"
        :disabled="disabled"
        :maxlength="maxlength"
        @change="change"
    >
        <template v-slot:prepend>
            <slot name="prepend"></slot>
        </template>
        <template v-slot:append>
            <slot name="append"></slot>
        </template>
    </el-input>
</template>

<script>
export default {
    name: 'FormInput',
    props: {
        value: {},
        size: {
            type: String,
            default: 'medium'
        },
        placeholder: {
            type: String,
            default: '请输入'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        maxlength: {
            type: Number,
            default: 32
        },
    },
    computed: {
        cpu_value: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
    },
    methods: {
        change(val) {
            // console.log('change:', val)

            this.$emit('change', val)
        },
    },
}
</script>